.Card{

    width: 300px;
    padding: 30px;
    position: relative;
  
}
.iconCard{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #EBEBEB;
}
#KouchDasturiBlock{
    padding-top: 80px;
}


.icons{
    width: 25px;
    height: 25px;
}
.icons:hover{
    background-color: #28468C ;
}
.Card:hover .iconCard{
    background-color: #28468C;
}
svg{
    fill: #0F0F0F;
    /* stroke: black; */
}
.icons:hover{
    fill: #fff !important;
}
.Card:hover svg *{
    fill: #fff;
}
.iconCard:hover{
    background-color: #28468C;
}

/* #ustunlikCard{
    width: 50% !important;
    height: auto !important;
} */
.CardSecondText{
    width: 100% !important;
}
.Cards{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.iconCard{
    z-index: 2;
}
#empty{
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 100%;
    background-color: #F7F7F7 !important;
    z-index: -5;
}
.KouchUstunligi{
    display: flex;
    flex-direction: column;
    padding-top: 120px;
}
.TopCards{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TopCardsText{
    font-family: 'SF Pro', 'sans-serif' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    width: 298px;
    height: 68px;
    color: #0F0F0F;
    margin: 0px;
}
.LinkRoyhat{
    height: 22px;
    color: #28468C;
    text-decoration-line: underline;
    margin-top: 20px;
    margin-bottom: 0px;
}
.CardFirstText{
    font-family: 'SF Pro',sans-serif;
font-style: normal;
font-weight: 510;
font-size: 20px;
line-height: 130%;
width: 300px;
height: 52px;
/* or 26px */
z-index: 15;


color: #0F0F0F;
}
.CardSecondText{
    
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    width: 330px;
    color: #111;
}



@media screen and (min-width: 1200px) {
    .KouchUstunligi{
        max-width: 1200px;
        margin: 0px auto;
        padding-bottom: 120px;
    }

    .Cards{
        flex-wrap: wrap !important;
    }
    
}

@media screen and (max-width: 947px) {
    .KouchUstunligi{
        margin: 40px 40px 0 40px;
    }
    .Card{
        width: 40%;
    }
    
    
    
}
@media screen and (max-width: 683px) {
    .Card{
        width: 100%;
    }
    
}
@media screen and (max-width: 576px) {
    .KouchUstunligi{
        margin: 80px 20px 0 20px;
    }
    .Cards{
        margin: 0px;
    }
    .Card{
        width: 100% !important;
        padding: 20px 0 20px 20px !important;
    }
    #CardSecondBlock{
        width: 100% !important;
    }
    #LinkRoyhat{
        display: block !important;
        margin-top: 20px;
    }
    .Navbar{
        margin: 0 20px;
    }
    .whoblockImg{
        height: auto !important;
    }
    #KouchDasturiBlock{
        padding-top: 80px;
    }
}
@media screen and (min-width: 576px) {
    #LinkRoyhat{
        display: none !important;
    }
}