.LogoSecItem{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 345px;
    z-index: 10;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
}
.LogoSecItem:hover{
    transition: 0.5s ease all;
}
.css-1sir9cm-MuiPaper-root{
    padding: 0;
}
.LogoSecItemImg{
    width: 380px ;

}
.asdas{
    display: flex;
    flex-direction: row;
    width: 100%;
}
/* .react-multi-carousel-list{
    width: 100vw;
} */

.FaqsLogoRow{
    width: 90%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    height: 150px;
    margin-top: 50px;
}
.ustkidiv{
    display: flex;
}
.ustkidiv > *{
    margin: 0 25px;
}

.LogoSection ::-webkit-scrollbar{
    width: 10px;
    height: 2px;
    background-color: inherit;
    margin-right: 10px;

}
::-webkit-scrollbar-thumb{
    background-color:#28468C;
    width: 20px;
    opacity: 0.5;
}

@media screen and (min-width:1200px) {
    .container{
        max-width: 1200px;
        margin: 0 auto;
    }
}
@media screen and (max-width:768px){
    .LogoSecItem{
        height: 70px !important;
    }
    .LogoSecItemImg{
        width: 170px !important;
        height: 70px !important;
    }
    .css-1sir9cm-MuiPaper-root{
        padding: 3px !important;
    }
    /* .css-1y5qi0o-MuiGrid-root>.MuiGrid-item ,
    .css-rog8y0-MuiGrid-root{
        width: 170px !important;
    } */
    .css-1y5qi0o-MuiGrid-root>.MuiGrid-item:nth-child(8){
        display: none !important;
    }
    
}
.LogoSecItem{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 345px;
    z-index: 10;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
}
.LogoSecItem:hover{
    transition: 0.5s ease all;
}
.css-1sir9cm-MuiPaper-root{
    padding: 0;
}
.LogoSecItemImg{
    width: 380px ;

}
.asdas{
    display: flex;
    flex-direction: row;
    width: 100%;
}
/* .react-multi-carousel-list{
    width: 100vw;
} */

.FaqsLogoRow{
    width: 90%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    height: 150px;
    margin-top: 50px;
}
.ustkidiv{
    display: flex;
}
.ustkidiv > *{
    margin: 0 25px;
}

.LogoSection ::-webkit-scrollbar{
    width: 10px;
    height: 2px;
    background-color: inherit;
    margin-right: 10px;

}
::-webkit-scrollbar-thumb{
    background-color:#28468C;
    width: 20px;
    opacity: 0.5;
}

@media screen and (min-width:1200px) {
    .container{
        max-width: 1200px;
        margin: 0 auto;
    }
}
@media screen and (max-width:768px){
    .LogoSecItem{
        height: 70px !important;
    }
    .LogoSecItemImg{
        width: 170px !important;
        height: 70px !important;
    }
    .css-1sir9cm-MuiPaper-root{
        padding: 3px !important;
    }
    /* .css-1y5qi0o-MuiGrid-root>.MuiGrid-item ,
    .css-rog8y0-MuiGrid-root{
        width: 170px !important;
    } */
    .css-1y5qi0o-MuiGrid-root>.MuiGrid-item:nth-child(8){
        display: none !important;
    }
    
}

#LogosecItem{
    /* height: auto !important; */
    width: 100% !important;
    padding: 0px !important;
}
.css-i9gxme{
    overflow: hidden;
    width: 100%;
}
#ustkidiv{
    width: 100% !important;
}
#LogoSecItemImg{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}
@media screen and (max-width: 1200px) {
    .react-multi-carousel-item{
        margin: 0 !important;
    }
    
}

@media screen and (max-width: 768px) {
    #LogosecItem{
        height: 100% !important;
        width: 90% !important;
        margin: 0 auto;
    }
    .react-multi-carousel-item{
        margin: 0px !important;
    }
    #LogoSecItemImg{
        object-fit: cover !important;
    }
    
}
@media screen and (max-width: 576px) {
    #LogosecItem{
        width: 90% !important;
        height: 100% !important;
        margin: 0px  auto!important;
    }
    #LogoSecItemImg{
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
    }
    .react-multi-carousel-item{
        margin: 0px !important ;
    }
    
}