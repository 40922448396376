.faqsCont{
    background: #FAFAFA;
}
.faqsCont .partTitle{
    margin-bottom: 40px !important;
}
.faqsContBox > * {
    margin: 0 10px;
}
/* .css-ta84vm-MuiAccordionDetails-root{
    border: none !important;
} */
.faqsContBox{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}
.bigBoxFaq{
    padding: 120px 0 !important;
    width: 100% !important;
    background-color: #FAFAFA !important;    
    margin-top: 70px;
}
.leftBox{
    display: flex;    
    flex-wrap: wrap;
    width: 30%;
}
.centerBox{
    display: flex;    
    flex-wrap: wrap;
    width: 30%;
}
.rightBox{
    display: flex;    
    flex-wrap: wrap;
    width: 30%;
}
.Faq__card{
    background-color: #FAFAFA !important;
    margin-bottom: 20px;
    /* border: none !important; */
    border-top: 3px solid #E1E1E1 !important;
    border-radius: 0;
    /* width: 380px ; */
    width: 110%;
    /* height: 100px !important; */
    height: max-content !important;
    padding-bottom: 20px;
}
.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root{
    background-color: #F0F0F0 !important;
}
/* .leftBox > :nth-child(1) {
    border-top: 3px solid #28468C !important;
} */

.KouchAccor__accordionFaq{
    width: 350px;
    margin: 20px 0;
    background-color: #F0F0F0 !important;
    border: none !important;
}
.KouchAccor__accordionFaq {
    transition: 0.1s ease all;
    animation: 0.1s ease all;
    border-top: 3px solid #E1E1E1 !important;
}
.KouchAccor__accordionFaq:hover {
    transition: 0.1s ease all;
    animation: 0.1s ease all;
    border-top: 3px solid #28468C !important;
}

.Faqs__cardTitle{
    color: #0F0F0F;
    font-size: 20px;
    line-height: 26px;
    font-weight: 510;
}
.faqCarTit{
    padding: 33px 0 20px 0 !important;
    margin: 0 !important;
    font-size: 20px !important;
}
#faqsCont{
    margin-top: 0px !important;
}
.faqCarTitBl{
    color: #28468C;
}
.Faq__car p{
    font-size: 18px;
}
.Faqs__cardContent{
    position: relative;
    color: #828282;
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 400;
    top: 10px;
    margin: 0 auto;
}
.accTextPartFaq{
    width: 100%;
}
 
.accordion__title {
    padding: 5px 10px; 
}

.accTextPartFaq{
    width: 100% !important;
    padding: 0 15px 0 20px !important;
}
.css-ahj2mt-MuiTypography-root{
    width: 90% !important;
}
.accordion__title {
    width: 80% !important;
}
#panel1d-header{
    height: 70px !important; 
}
@media screen and (min-width:1200px) {
    .container{
        max-width: 1200px;
        margin: 0 auto;
    }
    
}
@media screen and (max-width: 1200px) {
    .css-9l3uo3{
        width: 84%;
    }
    
}
@media screen and (max-width:947px) {
 
    .faqsContBoxTablet{
        display: flex;
        width: 100%;
        margin: 10px auto;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .faqsContBoxTablet > * {
        /* width: 250px !important; */
        margin: 10px 0 0 20px;
    }
    .Faq__card{
        width: 250px !important;
    }
    .accTextPartFaq{
        padding: 0px !important;
    }
}
@media screen and (min-width:769px) {
    .faqsContBox{
        display: flex;
    }
    .faqsContBoxTablet{
        display: none !important;
    }
}
@media screen and (max-width:768px) {
    .faqsContBox{
        display: none;
    }
    .faqsContBoxTablet{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
    }
    .faqAccTitle{
        width: 75% !important;
    }
}
@media screen and (max-width:500px) {
    .faqsContBoxTablet{
        display: none;
    }
    .faqsContBox{
        display: flex !important; 
    }
    .leftBox, .centerBox, .rightBox{
        /* margin-right: 50px; */
        width: 100%;
        margin: 10px 10000px;
    }
    .faqsCont .partTitle{
        margin-bottom: 20px !important;
    }
    .bigBoxFaq{
        padding-top: 20px !important;
        padding-bottom: 80px !important;
    }
    #FaqTitle{
        font-size: 1.1em !important;
    }
    
    .accTextPartFaq{
        padding-left: 10px !important;
    }
}
