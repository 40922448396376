.partTitle {
    width: 100%;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    margin: 15px 50px 40px 0px !important;
}
.CarouselItem{
    position: relative;
}

/* .KarouselAccor__cardPartBl {
    border-top: 3px solid #28468C !important;
} */

.Karousel__cardTitle {
    color: #0F0F0F;
    font-size: 20px;
    line-height: 26px;
    font-weight: 510;
}

.Karousel__cardContent {
    position: relative;
    color: #828282;
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 400;
    top: 10px;
    margin: 0 auto;
}

.Karousel__cardLink a {
    color: #0F0F0F !important;
    text-decoration: underline;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.48px;
    margin: 10px auto;
}

.Video__cardContent {
    position: absolute;
    bottom: 60px;
    margin-left: 15px !important;
    margin-bottom: 15px;
    font-weight: 274;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF !important;

}
.CarouselItem {
    top: 50px;
}
.CarouselVideo {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
}

.videoBlcCont {
    width: 320px;
    height: 214px;
    /* margin-right: 20px; */
    position: relative;
}

#carVid{
    margin-top: 40px !important;
    padding-top: 80px;
}




/* .react-multiple-carousel__arrow--left{
    position: absolute;
}
.react-multiple-carousel__arrow:hover{
    position: absolute;
} */

@media screen and (min-width:1200px) {
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }
}




@media screen and (max-width: 768px) {
    #carVid{
        margin-top: 0px;
    }
    /* .react-multi-carousel-item{
        margin-right: 300px !important;
    }       */

}
@media screen and (max-width: 576px) {
    #carVid{
        margin-top: 0px !important;
    }
    
}