.KouchDasturiBlock{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.KouchDasturiMini{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.KouchDText{
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    width: 298px;
}
.KouchMiniBlocks{
    display: flex;
    margin-top: 0px;
    width: 380px;
}
.DasturImg{
    width: 49px;
    height: 49px;
}
.hrDasturblock{
    width: 100%;
    height: 0.5px;
    background-color: #F0F0F0;
    border: 0.5px solid #F0F0F0;
    margin-top: 60px;
}
.DasturText{
    margin-left: 27px;
    margin-top: 0px;
    width: 290px;
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */


color: #828282;
}

@media screen and (min-width: 1200px) {
    .KouchDasturiBlock{
        max-width: 1200px;
        margin: 0px auto;
        padding-top: 120px;
    }
    
    
}
@media screen and (max-width: 1200px) {
    
}

@media screen and (max-width: 947px) {
    .KouchDasturiBlock{
        margin: 40px 40px 0 40px;
    }
    .KouchMiniBlocks{
        width: 45%;
    }
    
}

@media screen and (max-width: 576px) {
    .KouchDasturiBlock{
        margin: 0px 20px 0 20px !important;
    }
    .KouchMiniBlocks{
        width: 100%;
        margin: 0 20px 20px 0px;
    }
    .KouchDText{
        font-size: 24px;
        font-family: 'SF Pro';
        font-style: normal;
        width: 250px;
        font-weight: 700;
        font-style: normal;
    }
    .hrDasturblock{
        margin-top: 40px;
    }
    
}