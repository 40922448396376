.WhoBlock{
    display: flex;
    flex-direction: column;
}
.WhoBlockText{
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    width: 525px;
    height: 88px;
    color: #0F0F0F;
}
.whoBtnLink{
    text-decoration-line: none;
}
.WhoBlockBlocks{
    display: flex;
}
.WhoBLockCards{
    display: flex;
    flex-wrap: wrap;
}
#BlockCard{
    margin-top: 20px;
    width: 350px;
    height: 160px;

    height: auto;
}
/* .WhoLeftBlocks{
    height: 565px;;
} */
#btnRoyhat{
    margin-top: 20px;
    width: 246px;
    height: 65px;
    background-color: #28468C;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px !important; 
}
#WhoButton{
    display: none;
}
#WhoLink{
    margin-top: 40px;
    margin-bottom: 0px;
    display: block;
}
#CardSecondBlock{
    width: 330px;
}
.WhoBlockBlocks{
    margin-top: 40px;
}
#BlockCard4{
    margin-top: 20px;
    width: 350px;
    height: 160px;
}
.WhoImgBlock{
    margin-top: 20px;
}

#BlockCard{
    width: 70%;
    height: auto;
}
.ustozBlocks{
    display: flex;
    flex-wrap: wrap;
}
.Complect{
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* flex-direction: column; */
    margin-bottom: 50px;
}
#CardSecondBlock{
    font-size: 22px;
}

.whoblockImg{
    width: 400px;
    height: 220px;
    border-radius: 10px;
    object-fit: cover;
}
.WhoTopBlock{
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width:1200px) {
    .WhoBlock{
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 120px;
    }
    
    
}

@media screen and (max-width: 1200px) {
     .TwoCardsTwo{
        display: flex;
        justify-content: space-between;
    }
    #BlockCard4{
        height: 220px;
        padding-bottom: 0px !important;
        margin-left: 35px;
        margin-top: 100px;
        width: auto;
    } 
  
}

@media screen and (max-width: 947px) {
    .WhoBlock{
        margin: 0px 40px 0 40px;
        padding-top: 80px;
        margin-top: 0px !important;
    }
    .TwoCardsTwo{
        display: flex;
        justify-content: space-between;
    }
    .WhoImgBlock{
        position: absolute;
        right: 40px;
        margin-top: 0px;
    }
    #BlockCard{
        margin-top: 0px !important;
        margin-bottom: 20px;
        width: 100% !important;
        height: auto;
    }
    #BlockCard4{
        height: 220px;
        padding-bottom: 0px !important;
        margin-left: 35px;
        margin-top: 20px;
    }
    .WhoLeftBlocks{
        width: 100% ;
    }
    .WhoBlockText{
        margin-top: 20px;
    }
    .videosName, .videosName2{
        margin-right: 0px;
    }
    .Complect{
        flex-direction: column;
    }
    .whoblockImg{
        width: 100%;
        height: 450px;
    }
    
    
}
@media screen and (max-width: 768px) {
    #WhoButton{
        display: block;
    }
    #WhoLink{
        display: none;
    }
    
}
@media screen and (max-width: 576px) {
    .WhoBlock{
        margin: 20px 20px 0 20px !important;
    }
    .TwoCardsTwo{
        flex-direction: column;
    }
    #BlockCard4{
        margin-left: 0px;
    }
    .WhoImgBlock{
        position: block;
        margin-top: 0px !important;
    }
    .WhoBlockBlocks{
        flex-direction: column-reverse;
        margin-top: 0px;
    }
    .WhoImgBlock img{
        width: 100%;
        height: auto;
    }
    .WhoBlockText{
        width: 100%;
        font-family: 'SF Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #0F0F0F;
    }
    .WhoImgBlock{
        position: relative;
        width: 100%;
        margin: 20px 10%;
    }
    #CardSecondBlock{
        width: 100%;
    }
    .videosName, .videosName2{
        margin-right: 0px;
    }
    .Complect{
        margin-bottom: 30px;
    }
  
    
}