.MainTopBlock {
  display: flex;
  position: relative;
  margin-top: 160px !important;
}
.MainTopBlock img {
  width: 790px;
  height: auto;
 
}
.TopTextBlock {
  width: 410px;
  margin-bottom: 50px;
}
.TopBtn {
  display: flex;
}
.TopCarouselBlock {
  width: 100% !important; 
  z-index: -2;
  width: 790px;
}
.TopTextBlock h2 {
  position: absolute;
  font-family: "SF Pro", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 56px;
  width: 665px;
  text-align: start;
  /* top: 50px; */
  letter-spacing: -1.2px;
  /* or 112% */

  color: #0f0f0f;
}
.TopBtn {
  margin-top: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btnRoyhat {
  width: 246px;
  height: 65px;
  background-color: #28468c;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: white;
}
.tochka {
  width: 5px;
  height: 5px;
  background: black;
}
.kursHaqida {
  width: 124px !important;
  height: 38px !important;
}
.bir {
  color: #28468c;
}
.CarouselLow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.CarouselLowText {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #828282;
}
.firstCarousel {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  color: #28468c;
}
.allCarousel {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #afafaf;
  margin-top: 7px;
  letter-spacing: 2px;
}


#MainBlockText{
  transition: 0.5s;
  width: 100%;
}
#MainBlockText2{
  top: -120%;
  transition: 0.5s;
}
#span02{
  display: none;
}
.firstCarousel{
  display: flex;
}
@media screen and (min-width: 1200px) {
  .MainTopBlock {
    max-width: 1200px;
    margin: 70px auto;
    margin-bottom: 0px;
  }
  .FirstImgCarousel {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .MainTopBlock {
    width: 90%;
    margin: 0 5%;
    margin-top: 120px !important;
  }
  .CarouselMain {
    width: auto;
    margin: 0px !important;
  }
  .TopCarouselBlock {
    width: 100% !important;
    height: auto;
    margin-top: 40px;
  }
  .CarouselSize {
    width: 100% !important;
  }
  .CarouselMain {
    width: auto;
    margin: 0px !important;
  }
  /* .TopCarouselBlock {
    width: 60% !important;
    height: auto;
  } */
  .CarouselSize {
    width: 100% !important;
  }
  .CarouselMain {
    width: 100% !important;
  }
  .tochka{
    margin: 0 20px;
  }
}
@media screen and (max-width: 947px) {
  .MainTopBlock {
    margin: 80px 40px 0 40px !important;
  }
  .MainTopBlock {
    display: flex;
    flex-direction: column;
  }
  .TopBtn {
    margin-top: 28%;
    width: 412px;
  }
  .TopCarouselBlock {
    width: 100% !important;
    margin-top: 40px;
  }
  .MainTopBlock {
    margin-top: 40px;
  }
  .TopTextBlock h2 {
    position: absolute;
    margin: 0px;
    width: 100%;
  }
  .TopTextBlock {
    width: 100% !important;
  }
  
  .firstVideo{
    height: auto;
  }
  .tochka{
    margin: 0 20px;
  }
  .CarouselSize{
    height: 405px ;
  }
}
@media screen and (max-width: 664px) {
  .TopBtn{
    margin-top: 40% !important;
  }
  
}

@media screen and (max-width: 576px) {
  .TopTextBlock h2{
    width: 100%;
  }
  .MainTopBlock{
    margin: 100px 20px 0 20px !important;
  }
  .TopBtn{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .kursHaqida{
    margin-top: 20px;
  }
  .tochka{
    display: none;
    
  }
  .CarouselSize{
    height: 202px !important ;
  }
  .TopTextBlock h2{
    font-family: 'SF Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #0F0F0F;
  }
  .LinkRoyhat{
    display: none;
  }
  .lavhalar{
    display: none;
  }
  
  

  
}
@media screen and (max-width: 450px) {
  .TopTextBlock h2{
    font-family: 'SF Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* or 121% */


  color: #0F0F0F;
  }
  
}
