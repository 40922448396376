.KouchLavhalar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.VideoLavhalar{
    width: 298px;
    height: 68px;
}
.LinkRoyhat{
    /* width: 166px; */
    height: 22px;
}
.Videoss{
    display: flex;
    justify-content: space-between;
}
.firstVideo{
    width: 790px;
    height: 444px;
    margin-left: 20px auto;
    border: 1px solid #28468C;
}
.NavVideos{
    height: auto;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
}
.videosName{
    width: 380px !important;
    height: 65px;
    display: flex;
    /* justify-content: space-between; */
    text-align: center;
    align-items: center;
    background-color: #F0F0F0;
    border: none;
    width: 360px;
    padding: 20px ;
    margin-bottom: 30px;

}
.videosName2{
    height: 65px;
    width: 380px !important;
    display: flex;
    text-align: center;
    align-items: center;
    background-color: #F0F0F0;
    border: none;
    width: 360px;
    padding: 20px;
    transform:scaleY(1.15);
    margin-bottom: 30px;

}
.VideosText{
    margin-left: 20px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-decoration-line: underline;
    
    /* or 25px */

    color: #28468C;
}
.videosName .VideosText{
    color: #828282 !important;
    text-decoration-line: underline;
}
.FonVideos{
    background: #FAFAFA;
    width: 100%;
    /* padding-right: 200px; */
    


}

.VideosBlock{
  padding: 100px 0 120px;
}
.blueVidLink{
    color: #28468C;
}

@media screen and (min-width: 1200px) {
    .VideosBlock{
        max-width: 1200px;
        margin: 0 auto;
        background: #FAFAFA;
        
    }

    
}
@media screen and (max-width: 1200px) {
    .Videoss{
        flex-direction: row;
    }
    .videosName, .videosName2{
        width: 360px;
        margin-right: 20px;
    }
    .WhoImgBlock{
        position: absolute;
        right: 40px
    }
    
}
@media screen and (max-width: 947px) {
    .firstVideo{
        height: 405px;
    }
    .videosName, .videosName2{
        margin-bottom: 0px !important;
    }
    
}
@media screen and (max-width: 576px) {
    .firstVideo{
        height: 405;
    }
    .player{
        height: 202px !important;
    }
   
    
    
}
@media screen and (max-width: 450px) {
    .firstVideo{
        height: 202px !important;
    }
    
}
.Tabmeuvd{
    display: flex;
}