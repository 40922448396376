.bigbox {
    width: 100%;
    background-color: #FAFAFA;
}
.Footer{
    margin-top: 120px;
}
.upperFooter{
    margin-bottom: 60px;
}

.underFooter {
    background-color: #FAFAFA;
    width: 100%;
}

.footerForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
#footerNumber{
    justify-content: space-evenly !important;
}

.footerItem {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin: 20px 20px 0px;
    position: relative;
}
.swal-button{
    background-color: #28468C;
}
.footerItem p {
    position: absolute;
    top: -50px;
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 20px;
    line-height: 24px;
    color: #0F0F0F;

}

.footerItem input {
    height: 61px;
    width: 246px;
    font-family: 'SF Pro' !important;
    font-size: 1em;
    padding: 0px 10px;
}

.footerbtn {
    height: 65px;
    width: 270px;
    /* margin-top: 10px; */
    background-color: #28468C;
    color: #FFF;
    border-radius: 0;
    border: none;
}

.footerLink {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerLink ul {
    display: flex;
    flex-direction: row;
}

.footerLink ul li {
    list-style-type: none;
    margin-left: 34px;
}

.footerLink ul li a>* {
    text-decoration: none;
    color: #0F0F0F;
    font-size: 20px;
}

.footerLink ul li a>* :hover {
    color: #28468C;
}

.footerLi {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.underRow {
    height: 3px;
    border: 1px solid #F0F0F0;
    width: 100vw;
}

.footerLink__logo {
    margin: 0 32px 0 0;
}

.errorTxt {
    display: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #FF4646;
}

input {
    outline: none;
}

button {
    cursor: pointer;
}

.errorDet {
    border: 2px solid #FF4646;
}

.copyName {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #0F0F0F;
}

.copyBrand {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #B7B7B7;
    text-decoration: none;
}

.footerFormData {
    display: flex;
    flex-direction: row;
}
#bbb{
    height: 67px !important;
    width: 250px;
    padding: 10px;
    font-family: 'SF pro' !important;
    font-size: 16px;
}
.option {
    /* background-color: red; */
    padding: 30px 60px !important;
    font-size: 22px ;
    font-family: 'SF Pro' !important;

}
@media screen and (min-width:1200px) {
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .bigbox {
        width: 100% !important;
        /* margin: 0 auto; */
    }

    .footerForm {
        display: flex;
    }

    .footerFormGrid {
        display: flex;
        flex-direction: row;

    }
}

@media screen and (min-width: 500px) {

    /* .copyBrand{
        display: none;
    } */
    #copyBrand,
    .footerHR {
        display: none;
    }

}

@media screen and (max-width:1200px) {
    .footerForm {
        flex-wrap: flex;
    }
}

@media screen and (max-width:947px) {
    .footerItem {
        margin-top: 30px;
    }
}

@media screen and (max-width:500px) {
    .underFooter {
        display: flex;
        flex-direction: column;
    }
    #bbb {
        width: 270px;
    }

    .footerbtn{
        width: 270px;
    }
    .footerItem input{
        margin-bottom: 20px;
    }
    .footerItem p{
        font-size: 18px;
    }
    #footerBiznes input{
        margin-bottom: 0px !important;
    }

    .footerLink {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }

    .footerLink>* {
        text-align: center !important;

    }

    .footerLink ul {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0px;
    }

    .footerLink ul li {
        margin: 0 16px;
    }

    #footerNav {
        justify-content: space-around;
    }

    .footerLink {
        flex-direction: column-reverse;
    }

    .copyBrand {
        display: none;
    }

    #copyBrand {
        display: flex;
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .underFooter ul {
        margin-top: 0px;
    }

    .copyName {
        font-family: 'SF Pro' !important;
        font-size: 14px;
    }

    .footerHR {
        border: 1px solid #F0F0F0;
        width: 100%;
        margin-bottom: 0px;
    }

    .footerNavHR {
        display: none;
    }
    .Footer{
        margin-top: 80px !important;
    }
    #footerCont{
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 768px) {
    .footerFormData {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

}
