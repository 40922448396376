* {
    font-family: 'SF Pro', sans-serif !important;
}

.bigBox {
    width: 100%;
    background-color: #FAFAFA;
}
/* .css-3vmltg{
    content: '\2192' !important;
    z-index: 10000;
} */

.container {
    max-height: max-content;
    /* min-width: 1200px; */
    box-sizing: border-box;
    font-family: 'SF Pro', sans-serif;
    margin-top: 120px ;
    width: 100vw;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper{
    z-index: 1 !important;
    opacity: 1 !important;
    font-size: 22px !important;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper:hover{
    color: #28468C;
}

.KouchAccor {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    max-height: max-content;
    width: 100vw;
    margin: 120px auto 0 auto;
}

.partTitle {
    width: 100%;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    margin: 15px 0px;
}

.KouchAccor__inner {
    display: flex;
    flex-direction: row;
    /* width: 100vw; */
}

.KouchAccor__accordionPart {
    width: 65%;
    margin: 40px auto;

}
.KouchAccor__accordion:hover .kouchAccTitleBl{
    color: #28468C;
}
.innerAcc__text{
    display: flex;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #828282;
}
.KouchAccor__cardPart {
    width: 25%;
    margin: 40px auto;
}

.KouchAccor__accordion {
    margin: 20px 0;
    background-color: #F0F0F0 !important;
    border: none !important;
}

.css-ta84vm-MuiAccordionDetails-root {
    border: none !important;
}

.MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 30px;
}
.css-78njj9, .css-1fpbxjw{
    background-color: #f0f0f0 !important;
}

.css-1fpbxjw{
    border: none !important;
}

.accordionInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
}

.accordion__title {
    font-weight: 510;
    font-size: 20px;
}

/* .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
    background-color: #F0F0F0 !important;
}
.css-107vo98-MuiButtonBase-root-MuiAccordionSummary-root {
    background-color: #F0F0F0 !important;
} */
.css-pwcg7p-MuiCollapse-root{
    background-color: #F0F0F0 !important;
}
.css-107vo98-MuiButtonBase-root-MuiAccordionSummary-root{
    background-color: #F0F0F0 !important;
}
.css-1fjvggn-MuiPaper-root-MuiAccordion-root{
    background-color: #f0f0f0;
}
.css-1tp5dum{
    background-color: #f0f0f0 !important;
}
.css-1v5z4dq-MuiPaper-root-MuiCard-root {
    background-color: #F0F0F0 !important;
    height: 286px;
    width:350px !important;
    position: relative;
}
.cardAccorright, .kouchCardRight{
    background-color: #F0F0F0 !important;
}
.Kouch__card {
    margin-bottom: 20px;
    border: none !important;
    border-top: 3px solid #E1E1E1 !important;
    border-radius: 0;
}
.Kouch__card:hover{
    border-top: 3px solid #28468C !important;
}

/* .KouchAccor__cardPartBl {
    border-top: 3px solid #28468C !important;
} */
.KouchAccor__cardPartBl:hover{
    border-top: 3px solid #28468C !important;
}

.css-1okfn8i {
    /* background-color: #f0f0f0 !important; */
    height: 286px !important;
    width:350px !important;
}

/* .KouchAccor__accordion:first-child .accordion__title,
.KouchAccor__accordion:first-child .MuiAccordionSummary-expandIconWrapper {
    color: #28468C;
} */

.Kouch__cardTitle {
    color: #0F0F0F !important;
    font-size: 20px !important;
    line-height: 26px !important;
    font-weight: 510 !important ;
    /* margin-top: 20px !important;  */
}

.css-ag7rrr-MuiTypography-root {
    color: #0F0F0F;
    font-size: 20px !important;
    line-height: 26px;
    font-weight: 510;
}

.Kouch__cardContent {
    position: relative !important;
    color: #828282 !important;
    font-size: 18px !important;
    line-height: 25.2px !important;
    font-weight: 400 !important;
    top: 10px !important;
    margin: 20px auto !important;
    
}
.Kouch__card{
    position: relative;
}
.Kouch__cardLink {
    color: #0F0F0F !important;
    text-decoration: underline;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.48px;
    margin: 100px 0 auto;
    position: absolute;
    bottom: 30px;
    width: 200px;
}
.css-1t6e9jv-MuiCardActions-root{
    position: absolute;
    bottom:20px;
}
/* .css-1v5z4dq-MuiPaper-root-MuiCard-root {
} */
.KouchDasturi{
    margin: 0px;
    padding-top: 80px;
}

.accTextPart{
    width: 95% !important;
    margin: 0 auto !important;
}
/* .KouchAccor__accordion:hover .css-yw020d-MuiAccordionSummary-expandIconWrapper svg {
    color: #28468C !important;
    fill: #28468C !important;
}
.KouchAccor__accordion:hover .css-yw020d-MuiAccordionSummary-expandIconWrapper svg::before{
    top: 50px;
    z-index: 10;
    position: absolute;
    fill: #28468C !important;
    color: #28468C !important;
}  */
#KOuchAccorc .KouchAccor__accordion:hover svg{
    color: #28468C !important;
    fill: #28468C !important; 
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper{
    position: absolute;
    right: 15px;
}

@media screen and (min-width:1200px) {
    .container{
        max-width: 1200px;
        margin: 0 auto;
    }
}

@media screen and (max-width:947px) {
    .KouchAccor__cardPart {
        display: flex;
        width: 100%;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }

    .css-12dv9jb {
        width: 70% !important;
    }

    .css-0 {
        margin: 0 20px;
    }

}

@media screen and (max-width: 950px) {
    .container {
        max-width: 720px;
        margin: 120px auto 0 auto ;
    }

    .KouchAccor__cardPart {
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: row;
    }

    .css-ahj2mt-MuiTypography-root {
        display: flex !important;
        font-weight: 510;
        font-size: 20px;
        line-height: 24px !important;
        /* color: #0F0F0F !important; */
    }
    .accText {
        display: flex !important;
        color: #828282 !important;
    }
    .accText,
    .accordionInner {
        font-family: 'SF Pro';
        color: #828282 !important;
    }
 
    .KouchAccor__inner {
        display: flex;
        flex-direction: column;
        /* width: 100vw; */
    }

    .KouchAccor__accordionPart {
        width: 90%;
        margin: 40px auto;
    }
}
@media screen and (max-width: 768px) {
    .KouchAccor__cardPart{
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .innerAcc__text{
        flex-direction: column;
    }
    .accTextPart {
        width: 100% !important;
        margin-bottom: 20px !important;
        padding: 0 5px !important
    }
}
@media screen and (max-width: 500px) {
    .container {
        width: 360px;
    }

    .accTextPart {
        width: 100% !important;
        margin-bottom: 20px !important;
      
    }
    .Kouch__card{
        height: 300px;
        width: 360px;
    }
    .partTitle {
        width: 100%;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        margin: 15px 0px;
        color: #0F0F0F;
    }
    .KouchAccor__accordionPart {
        width: 90%;
        margin: 40px auto;
    }
    .KouchAccor__cardPart  {
        width: 100% !important;
    }
    .KouchAccor{
        margin-top: 80px !important;
    }
    .KouchAccor__accordionPart{
        width: 100% !important;
    }
    .css-ahj2mt-MuiTypography-root{
        width: 87% !important;
    }
    .kouchAccTitleBl{
        width: 87% !important;
        
    }
   .css-ta84vm-MuiAccordionDetails-root{
        width: 98% !important;
   }
}
